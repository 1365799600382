@tailwind base;
@tailwind components;
@tailwind utilities;

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
}

.arrow {
  pointer-events: none;
}
 
.arrow__path {
  stroke: #000;
  fill: transparent;
  stroke-dasharray: 4 2;
}
 
.arrow__head line {
  stroke: #000;
  stroke-width: 1px;
}

body {
  height: 100vh;
  width: 100vw;
}